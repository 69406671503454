import * as winwheel from '@evshiron/winwheel.js'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import imgs from '../../assets/images'
import { Modal } from '../../components/common'
import { Button, Select } from '../../components/elements'

import { sample } from 'lodash'
import {
  checkSpin,
  countSpin,
  fetchDataLuckyDraw,
  fetchStoreList,
  luckyDrawSelector,
  setIsLoading,
  setPrizeItem,
  updateChooseStore,
  userSelector,
} from '../../slices'
import { showMessage, staticLinks } from '../../utils'

let theWheel = {}
let secondImg = new Image()
let prizeItem = null

export const LuckyDrawPage = () => {
  const canvasRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { remain, total } = useSelector(luckyDrawSelector)
  const { userData } = useSelector(userSelector)

  const [startWheel, setStartWheel] = useState(false)
  const [winnerVisible, setWinnerVisible] = useState(false)
  const [loserVisible, setLoserVisible] = useState(false)
  const [storeItems, setStoreItem] = useState([])
  const [rewardID, setRewardID] = useState('')
  const [storeAddress, setStoreAddress] = useState('')
  const [storeAddressNew, setStoreAddressNew] = useState('')

  const validationSchema = Yup.object().shape({
    store_id: Yup.string().required('Vui lòng chọn cửa hàng'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm(formOptions)

  const onSubmit = ({ store_id }) => {
    const data = {
      store_id,
      reward_id: rewardID,
    }

    dispatch(setIsLoading(true))

    updateChooseStore(data)
      .then(() => {
        dispatch(setIsLoading(false))
        resetWheel()
        setWinnerVisible(false)
        setStartWheel(false)
        setRewardID('')
        setStoreItem([])
        toast('Xác nhận chọn cửa hàng thành công')
      })
      .catch((error) => {
        const { error_message } = error
        dispatch(setIsLoading(false))
        showMessage(error_message, (message) => {
          toast(message)
        })
      })
  }

  const resetWheel = () => {
    theWheel.stopAnimation(false)
    theWheel.rotationAngle = 0
    theWheel.draw()
  }

  const alertPrize = () => {
    dispatch(setIsLoading(true))

    setTimeout(() => {
      setWinnerVisible(true)
      dispatch(setIsLoading(false))
    }, 500)
  }

  const startSpin = () => {
    if (userData) {
      setStartWheel(true)
      dispatch(setIsLoading(true))
      canvasRef?.current.classList.remove('animate-wheel')

      // checkSpin()
      //   .then((response) => {
      //     const { error_code, error_message, data } = response

      //     if (!error_code) {
      //       dispatch(setIsLoading(false))

      //       if (data?.remain > 0) {
      //         fetchDataLuckyDraw()
      //           .then((response) => {
      //             const { data } = response
      //             const { reward_id } = data
      //             const arrPrize1 = [1, 3, 5, 7, 9] //ticket
      //             const arrPrize2 = [2, 8] //lythuytinh
      //             const arrPrize3 = [4] //kedienthoai
      //             const arrPrize4 = [6, 10] //lynhuadoimau
      //             let segmentNumber = 1
      //             if (data?.reward_data?.name === 'ticket') {
      //               segmentNumber = sample(arrPrize1)
      //             } else {
      //               setRewardID(reward_id)
      //               const data = {
      //                 reward_id,
      //               }
      //               fetchStoreList(data)
      //                 .then((response) => {
      //                   const { data } = response
      //                   setStoreItem(data)
      //                 })
      //                 .catch((error) => {
      //                   console.log(error)
      //                 })
      //             }

      //             if (data?.reward_data?.name === 'lynhuadoimau') {
      //               segmentNumber = sample(arrPrize2)
      //             }

      //             if (data?.reward_data?.name === 'kedienthoai') {
      //               segmentNumber = sample(arrPrize3)
      //             }

      //             if (data?.reward_data?.name === 'lythuytinh') {
      //               segmentNumber = sample(arrPrize4)
      //             }

      //             prizeItem = data
      //             dispatch(setPrizeItem(data))
      //             setStoreAddress(data?.store?.address)
      //             dispatch(countSpin())

      //             if (segmentNumber) {
      //               let stopAt = theWheel.getRandomForSegment(segmentNumber)
      //               theWheel.animation.stopAngle = stopAt
      //               theWheel.animation.callbackFinished = alertPrize
      //             }

      //             theWheel.stopAnimation(false)
      //             theWheel.rotationAngle = theWheel.rotationAngle % 360
      //             theWheel.startAnimation()
      //           })
      //           .catch((error) => {
      //             const { error_message } = error
      //             dispatch(setIsLoading(false))
      //             showMessage(error_message, (message) => {
      //               toast(message)
      //             })
      //           })
      //       } else {
      //         setLoserVisible(true)
      //       }
      //     } else {
      //       dispatch(setIsLoading(false))
      //       showMessage(error_message, (message) => {
      //         toast(message)
      //       })
      //     }
      //   })
      //   .catch((error) => {
      //     const { error_message } = error
      //     dispatch(setIsLoading(false))
      //     showMessage(error_message, (message) => {
      //       toast(message)
      //     })
      //   })
    } else {
      navigate(staticLinks.login.url)
    }
  }

  useEffect(() => {
    const wheelConfig = {
      numSegments: 10,
      pointerAngle: 180,
      canvasId: 'myCanvas',
      drawMode: 'image',
      segments: [
        { fillStyle: '#eae56f', text: 'Prize 1' },
        { fillStyle: '#89f26e', text: 'Prize 2' },
        { fillStyle: '#7de6ef', text: 'Prize 3' },
        { fillStyle: '#e7706f', text: 'Prize 4' },
        { fillStyle: '#eae56f', text: 'Prize 5' },
        { fillStyle: '#89f26e', text: 'Prize 6' },
        { fillStyle: '#89f26e', text: 'Prize 7' },
        { fillStyle: '#89f26e', text: 'Prize 8' },
        { fillStyle: '#89f26e', text: 'Prize 9' },
        { fillStyle: '#89f26e', text: 'Prize 10' },
      ],
      animation: {
        type: 'spinToStop',
        duration: 10,
        spins: 8,
      },
      pointerGuide: {
        display: false,
        strokeStyle: '#000',
        lineWidth: 1,
      },
    }

    const drawWheel = true
    theWheel = new winwheel.Winwheel(wheelConfig, drawWheel)
    secondImg.onload = function () {
      theWheel.wheelImage = secondImg
      theWheel.draw()
    }

    secondImg.src = imgs.luckydraw
  }, [])

  useEffect(() => {
    if (!userData) navigate(staticLinks.login.url)
  }, [userData, navigate])

  return (
    <div className="page page--center page--luckydraw">
      <Helmet>
        <title>Vòng xoay may mắn - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="luckydraw">
        <div className="container">
          <div className="wheel">
            <div className="wheel__head" data-aos="fade-left">
              <h2 className="wheel__title">Chào mừng bạn đến với</h2>
              <h3 className="title title--primary">
                Vòng xoay
                <span>quyền năng Galaxy AI </span>
              </h3>
              <p className="wheel__desc">
                <span>Lượt quay hôm nay dành cho bạn đã sẵn sàng!</span> Quay ngay và thu thập thật
                nhiều PHIẾU DỰ THƯỞNG cùng hàng trăm QUÀ TẶNG ĐỘC QUYỀN thôi nào. Chúc bạn thật
                nhiều may mắn!
              </p>
            </div>
            <div className="wheel__body" data-aos="fade-right">
              <div className="winwheel">
                <div className="winwheel__body">
                  <canvas
                    ref={canvasRef}
                    className="animate-wheel"
                    id="myCanvas"
                    width={440}
                    height={440}
                    data-responsiveminwidth="180"
                    data-responsivescaleheight="true"
                    data-responsivemargin="50"
                  />
                  <button
                    className="winwheel__spin"
                    onClick={startSpin}
                    disabled={startWheel ? true : false}
                    id="spin"
                  >
                    <span className="pulse">Quay ngay</span>
                  </button>
                </div>
                <div className="winwheel__action">
                  {remain > 0 ? (
                    <p className="number-wheel">
                      Số lượt quay còn lại trong ngày <span className="number">{remain}</span>/
                      <span>{total}</span>
                    </p>
                  ) : (
                    <p>
                      Bạn đã hết lượt quay.{' '}
                      <span className="d-block">Hãy quay lại vào ngày mai nhé.</span>
                    </p>
                  )}
                  {/* <p className="prize-total">
                    <span>
                      Số lượng Bộ Ly Thủy Tinh còn lại: <strong>309</strong>
                    </span>
                    <span>
                      Số lượng Ly Nhựa Đổi Màu còn lại: <strong>234</strong>
                    </span>
                    <span>
                      Số lượng ⁠Giá đỡ Điện Thoại còn lại: <strong>347</strong>
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={loserVisible} className="modal--loser">
        <div className="modal__main">
          <div className="modal__light">
            <Button
              type="button"
              onClick={() => {
                resetWheel()
                setLoserVisible(false)
                setStartWheel(false)
              }}
              className="modal__close"
              id="btn-luckydraw-loser-close"
              withAnimation
            >
              <span>Close</span>
            </Button>
            <div className="modal__body">
              <div className="loser text-center">
                <h2 className="loser__title">
                  Hic, tạm biệt nhau <span>một lúc nhé!</span>
                </h2>
                <p className="loser__desc">
                  Lượt quay mới của bạn sẽ đến sớm thôi! Đừng quên rằng cơ hội thu thập phiếu dự
                  thưởng vẫn tiếp tục diễn ra đến hết 30/4. Hẹn gặp lại vào lượt quay số mới sau
                  24h!
                </p>
                <Button
                  type="button"
                  onClick={() => {
                    resetWheel()
                    setLoserVisible(false)
                    setStartWheel(false)
                    setTimeout(() => navigate(staticLinks.home.url), 300)
                  }}
                  className="btn btn--primary px-5"
                  id="btn-luckydraw-again"
                  withAnimation
                >
                  <span className="btn__text">Quay lại trang chủ</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {prizeItem !== null && (
        <Modal visible={winnerVisible} className="modal--winner">
          <div className="modal__main">
            <div className="modal__light">
              <Button
                type="button"
                onClick={() => {
                  resetWheel()
                  setWinnerVisible(false)
                  setStartWheel(false)
                }}
                className="modal__close"
                id="btn-luckydraw-winner-close"
                withAnimation
              >
                <span>Close</span>
              </Button>
              <div className="modal__body">
                {prizeItem?.reward_data?.type === 'ticket' ? (
                  <div className="prize-winner text-center">
                    <h2 className="prize-winner__title">
                      Chúc mừng bạn đã dành được <span className="title">Phiếu dự thưởng</span>
                    </h2>
                    <div className="prize-winner__image">
                      <img src={imgs.phieuDuThuong} alt="" className="img" />
                    </div>
                    <p className="prize-winner__desc">
                      Mã phiếu dự thưởng sẽ được gửi về Email bạn đã đăng ký. Vui lòng mang phiếu dự
                      thưởng xuống cửa hàng trải nghiệm Samsung để xác minh và có cơ hội nhân đôi cơ
                      hội trúng quà.
                    </p>
                    <p className="prize-winner__desc">
                      Đừng quên là cơ hội thu thập phiếu dự thưởng vẫn tiếp tục diễn ra đến hết ngày
                      18/05. Hẹn gặp lại bạn vào lượt quay mới sau 24h!
                    </p>

                    <div className="d-block mb-3">
                      <Button
                        type="button"
                        className="btn btn--link btn--uppercase btn--bold btn--italic"
                        onClick={() => {
                          setWinnerVisible(false)
                          setTimeout(() => navigate(staticLinks.storeList.url), 300)
                        }}
                        withAnimation
                      >
                        Tìm cửa hàng Trải nghiệm Samsung gần nhất &gt;
                      </Button>
                    </div>
                    <div className="d-block">
                      <Button
                        type="button"
                        className="btn btn--primary px-5"
                        id="share_luckydraw"
                        onClick={() => {
                          window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
                          )
                        }}
                        withAnimation
                      >
                        Chia sẻ
                        <i className="icon icon--facebook"></i>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="prize-winner text-center">
                    <h2 className="prize-winner__title">
                      Chúc mừng bạn đã trúng thưởng quà độc quyền từ{' '}
                      <span className="title">Vòng xoay quyền năng Galaxy AI</span>
                    </h2>
                    <div className="prize-winner__image">
                      {prizeItem?.reward_data?.name === 'lynhuadoimau' && (
                        <img src={imgs.lynhuadoimau} alt="" className="img" />
                      )}
                      {prizeItem?.reward_data?.name === 'lythuytinh' && (
                        <img src={imgs.lythuytinh} alt="" className="img" />
                      )}
                      {prizeItem?.reward_data?.name === 'kedienthoai' && (
                        <img src={imgs.kedienthoai} alt="" className="img" />
                      )}
                    </div>
                    <p>
                      Mời bạn đến Cửa hàng trải nghiệm Samsung{' '}
                      <strong>{storeAddressNew ? storeAddressNew : storeAddress}</strong> xuất trình
                      email để đổi quà may mắn là{' '}
                      <strong>
                        {prizeItem?.reward_data?.name === 'lynhuadoimau' && (
                          <>Ly Galaxy Đổi Màu độc quyền.</>
                        )}
                        {prizeItem?.reward_data?.name === 'lythuytinh' && (
                          <>Bộ ly thuỷ tinh phiên bản giới hạn.</>
                        )}
                        {prizeItem?.reward_data?.name === 'kedienthoai' && (
                          <>Giá đỡ điện thoại Phi Hành Gia.</>
                        )}
                      </strong>
                    </p>
                    {storeItems.length > 0 && (
                      <form className="form form--store" onSubmit={handleSubmit(onSubmit)}>
                        <Select
                          placeholder="Chọn cửa hàng"
                          name="store_id"
                          id="store_id"
                          register={register}
                          options={storeItems}
                          error={errors.store_id}
                          onChange={(value) => {
                            if (value) {
                              setStoreAddressNew(value?.address)
                            } else {
                              setStoreAddressNew('')
                            }
                          }}
                          setValue={setValue}
                        />
                        <Button
                          type="submit"
                          className="btn btn--primary px-5"
                          id="signup_completed"
                          disabled={!isValid}
                          withAnimation
                        >
                          <span className="btn__text">Xác nhận</span>
                        </Button>
                      </form>
                    )}
                    <p>
                      <strong>Lưu ý</strong>: Thời hạn đổi quà giới hạn trong vòng 5 ngày kể từ ngày
                      nhận được email thông báo! Đừng quên là cơ hội thu thập phiếu dự thưởng cùng
                      nhiều phần quà khác vẫn tiếp tục diễn ra đến hết 18/05.
                    </p>
                    <div className="d-block">
                      <Button
                        type="button"
                        className="btn btn--primary px-5"
                        id="share_gift"
                        onClick={() => {
                          window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
                          )
                        }}
                        withAnimation
                      >
                        Chia sẻ
                        <i className="icon icon--facebook"></i>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
