import React, { useEffect } from 'react'

export const Modal = ({ children, className, visible, onClose }) => {
  const handleClick = () => {
    if (onClose) onClose()
  }

  useEffect(() => {
    if (visible) document.querySelector('body').classList.add('no-scroll')
    else document.querySelector('body').classList.remove('no-scroll')
  }, [visible])

  return (
    <div
      className={
        visible
          ? `modal ${className ? className : ''} modal--show`
          : `modal ${className ? className : ''}`
      }
      onClick={handleClick}
    >
      <div className="modal__dialog">{children}</div>
    </div>
  )
}
