export const staticLinks = {
  home: {
    label: 'Trang chủ',
    url: '/',
  },
  login: {
    label: 'Đăng nhập',
    url: '/dang-nhap/',
  },
  register: {
    label: 'Đăng ký',
    url: '/dang-ky/',
  },
  myProfile: {
    label: 'Thông tin cá nhân',
    url: '/thong-tin-ca-nhan/',
  },
  forgotPassword: {
    label: 'Quên mật khẩu',
    url: '/quen-mat-khau/',
  },
  changePassword: {
    label: 'Thay đổi mật khẩu',
    url: '/thay-doi-mat-khau/',
  },
  luckydraw: {
    label: 'Vòng xoay quyền năng',
    url: '/vong-xoay-quyen-nang/',
  },
  termAndConditions: {
    label: 'Điều kiện &amp; điều khoản',
    url: '/dieu-kien-va-dieu-khoan/',
  },
  storeList: {
    label: 'Danh sách cửa hàng',
    url: '/danh-sach-cua-hang/',
  },
  notFound: {
    label: '404',
    url: '/*',
  },
}
