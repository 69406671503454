import { yupResolver } from '@hookform/resolvers/yup'
import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Button, Input } from '../../components/elements'
import { showMessage, staticLinks } from '../../utils'
import { setDataLogin, setIsLoading, userLogin } from '../../slices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'

window.recaptchaOptions = {
  useRecaptchaNet: true,
}

export const LoginPage = () => {
  const reCaptcha = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [message, setMessage] = useState('')
  const [isCaptcha, setIsCaptcha] = useState(false)

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Vui lòng nhập địa chỉ email')
      .test('Validate Email', 'Vui lòng nhập đúng định dạng email', (value) => {
        if (value !== '') {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(value).toLowerCase())
        } else {
          return true
        }
      }),
    password: Yup.string().required('Vui lòng nhập mật khẩu'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm(formOptions)

  const onSubmit = ({ username, password }) => {
    const data = {
      username,
      password,
    }

    if (reCaptcha.current.getValue() === '') {
      setIsCaptcha(false)
      setMessage('Vui lòng nhập captcha')
      return
    } else {
      setIsCaptcha(true)
      setMessage('')

      dispatch(setIsLoading(true))

      userLogin(data)
        .then((response) => {
          const {
            error_code,
            error_message,
            data: { player },
          } = response

          dispatch(setIsLoading(false))

          if (!error_code) {
            toast('Đăng nhập thành công')
            dispatch(setDataLogin(player))
          } else {
            showMessage(error_message, (message) => {
              toast(message)
            })
          }
        })
        .catch((error) => {
          const { error_message } = error
          dispatch(setIsLoading(false))
          showMessage(error_message, (message) => {
            toast(message)
          })
        })
    }
  }

  const handleChange = () => {
    setIsCaptcha(true)
    setMessage('')
  }

  const handleExpired = () => {
    if (reCaptcha.current.getValue() === '') {
      setIsCaptcha(false)
    }
  }

  return (
    <div className="page page--center page--auth">
      <Helmet>
        <title>Đăng nhập - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="container">
        <div className="auth">
          <div className="box-light box-light--type2">
            <div className="box-light__main">
              <div className="box-light__body">
                <div className="row">
                  <div className="col-md-6 col-lg-4 offset-lg-4 offset-md-3">
                    <h2 className="title title--primary text-center">Đăng nhập</h2>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        label="Email"
                        name="username"
                        type="email"
                        id="email"
                        register={register}
                        error={errors.username}
                        setValue={setValue}
                      />
                      <Input
                        label="Mật khẩu"
                        name="password"
                        type="password"
                        id="password"
                        register={register}
                        error={errors.password}
                        setValue={setValue}
                      />
                      <div className="mb-3">
                        <Button
                          type="button"
                          onClick={() => navigate(staticLinks.forgotPassword.url)}
                          className="btn btn--link"
                          withAnimation
                        >
                          Quên mật khẩu?
                        </Button>
                      </div>
                      <div className="col col-12">
                        <ReCAPTCHA
                          ref={reCaptcha}
                          className="captcha"
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                          onChange={handleChange}
                          onExpired={handleExpired}
                          hl="vi"
                        />
                        <span className="error">{message}</span>
                      </div>
                      <div className="mt-3 text-center">
                        <Button
                          type="submit"
                          className="btn btn--primary px-5"
                          disabled={!isValid || !isCaptcha}
                          id="signin"
                          withAnimation
                        >
                          <span className="btn__text">Đăng nhập</span>
                        </Button>
                        <p className="my-3">
                          Bạn chưa có tài khoản?{' '}
                          <Button
                            className="btn btn--link"
                            type="button"
                            onClick={() => navigate(staticLinks.register.url)}
                            id="signup"
                            withAnimation
                          >
                            Đăng ký ngay
                          </Button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
