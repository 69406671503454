import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { userApi } from "../apis";

export const fetchDataGalleryByUser = createAsyncThunk(
  "user/fetchDataGalleryByUser",
  async (data) => await userApi.fetchDataByUser(data)
);

export const fetchDataReward = createAsyncThunk(
  "user/fetchDataReward",
  async (data) => await userApi.fetchDataReward(data)
);

export const updateDataGalleryByUser = async (data) =>
  await userApi.fetchDataByUser(data);

export const userLogin = async (data) => await userApi.userLogin(data);

export const userRegister = async (data) => await userApi.userRegister(data);

export const verifyAccount = async (data) => await userApi.verifyAccount(data);

export const forgotPassword = async (data) =>
  await userApi.forgotPassword(data);

export const changePassword = async (data) => await userApi.newPassword(data);

export const updateProfile = async (data) => await userApi.updateProfile(data);

export const updateDataReward = async (data) =>
  await userApi.fetchDataReward(data);

export const fetchStoreList = async (data) =>
  await userApi.fetchStoreList(data);

export const updateChooseStore = async (data) =>
  await userApi.updateChooseStore(data);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null,
    loginData: null,
    registerData: null,
    email: null,
    rewardItems: [],
    current_page: 1,
    last_page: 3,
    per_page: 6,
    total: 0,
    currentPage: null,
  },
  reducers: {
    setDataLogin: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem("userData", JSON.stringify(action.payload));
    },
    setDataRegister: (state, action) => {
      state.registerData = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    logout: (state) => {
      state.userData = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("photoContextGuide");
    },
    setDataReward: (state, action) => {
      const {
        data: { data, current_page, last_page, per_page, total },
      } = action.payload;

      state.rewardItems = data;
      state.current_page = current_page;
      state.last_page = last_page;
      state.per_page = per_page;
      state.total = total;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchDataReward
      .addCase(fetchDataReward.fulfilled, (state, action) => {
        const {
          data: { data, current_page, last_page, per_page, total },
        } = action.payload;

        state.rewardItems = data;
        state.current_page = current_page;
        state.last_page = last_page;
        state.per_page = per_page;
        state.total = total;
      });
  },
});

export const {
  setDataLogin,
  setDataRegister,
  setEmail,
  setCurrentPage,
  setDataGalleryByUser,
  logout,
} = userSlice.actions;

export const { setDataReward } = userSlice.actions;

export const userSelector = createSelector(
  (state) => ({
    registerData: state.user.registerData,
    loginData: state.user.loginData,
    userData: state.user.userData,
    email: state.user.email,
    rewardItems: state.user.rewardItems,
    current_page: state.user.current_page,
    last_page: state.user.last_page,
    per_page: state.user.per_page,
    total: state.user.total,
  }),
  (state) => state
);

export default userSlice.reducer;
