import React from 'react'
import imgs from '../../assets/images'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__body">
        <div className="container">
          <div className="footer__row">
            <div className="footer__col">
              <p>Bản quyền ©1995-2023 Samsung bảo lưu mọi quyền.</p>
              <p>
                <span>Công ty TNHH Điện Tử Samsung Vina</span>
                <span>Giấy CNĐT: 411043002319, do UBND TP HCM cấp ngày 28/8/2007</span>
                <span>
                  Địa chỉ: Số 2, đường Hải Triều, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh
                </span>
                <span>Điện thoại: +84-2839157310</span>
              </p>
            </div>
            <div className="footer__col">
              <div className="footer-logo">
                <img src={imgs.govLogos3} alt="" className="img" />
                <img src={imgs.govLogos2} alt="" className="img" />
                <img src={imgs.govLogos1} alt="" className="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__foot">
        <div className="container">
          <div className="footer__social">
            <p>Kết Nối với Chúng Tôi</p>
            <ul className="social">
              <li className="social__item">
                <Link to="https://www.facebook.com/SamsungVietnam" target="_blank">
                  <img src={imgs.facebook} alt="" className="img" />
                </Link>
              </li>
              <li className="social__item">
                <Link to="https://www.youtube.com/user/samsungvietnam" target="_blank">
                  <img src={imgs.youtube} alt="" className="img" />
                </Link>
              </li>
              <li className="social__item">
                <Link to="https://www.instagram.com/samsung_vietnam" target="_blank">
                  <img src={imgs.instagram} alt="" className="img" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
