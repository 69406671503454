import React, { useRef } from 'react'
import imgs from '../assets/images'
import { Button } from '../components/elements'
import { staticLinks } from '../utils'
import { useNavigate, Link } from 'react-router-dom'

export const HomePage = () => {
  const homeCampaignRef = useRef()
  const navigate = useNavigate()

  const handleClick = () => {
    const join = document.querySelector('.join')
    const headerH = document.querySelector('.header').clientHeight
    const topPos = join.getBoundingClientRect().top + window.scrollY
    window.scrollTo({
      top: topPos - headerH,
      behavior: 'smooth',
    })
  }

  return (
    <div className="page page--home">
      <div className="welcome">
        <div className="container">
          <div className="welcome__main">

            {/* <div className="welcome__item" data-aos="fade-left">
              <h2 className="welcome__title">Cửa hàng trải nghiệm Samsung</h2>
              <h3 className="title title--primary">
                Vòng xoay
                <span>quyền năng Galaxy AI</span>
              </h3>
              <p className="welcome__desc">
                Hân hạnh mang đến bạn hành trình trải nghiệm siêu phẩm công nghệ Galaxy S24 Series
                vô cùng hấp dẫn! Nào cùng khởi động với vòng xoay may mắn với giải thưởng lớn là
                siêu phẩm Galaxy S24 cùng những phần quà độc quyền không thể bỏ lỡ khác! Thử vận may
                và đến ngay cửa hàng trải nghiệm Samsung lãnh thưởng thôi nào!
              </p>
              <Button
                onClick={handleClick}
                className="btn btn--primary"
                id="topbanner_discovery"
                withAnimation
              >
                Khám phá ngay
              </Button>
            </div> */}

            <div className="welcome__item" data-aos="fade-left">
              <h2 className="welcome__title">Thông báo kết thúc chương trình</h2>
              <h3 className="title title--primary">
                Vòng xoay
                <span>quyền năng Galaxy AI</span>
              </h3>
              <p className="welcome__desc">
              Cửa Hàng Trải Nghiệm Samsung xin cảm những người chơi đã tham gia vào chương trình “Vòng Xoay Quyền Năng Galaxy AI".
              </p>

              <div class="finalEvent">
                {/* <address>
                  <p>Chương trình rút thăm may mắn sẽ được tổ chức tại <br></br><strong>Cửa Hàng Trải Nghiệm Samsung</strong></p>
                  T-07 Tầng 1 Trung Tâm Thương Mại Gigamall Phạm Văn Đồng, 
                  240-242 đường Kha Vạn Cân, Phường Hiệp Bình Chánh, Tp. Thủ Đức 
                  vào ngày 24/05/2024.
                </address>
                <time>
                  <div class="liveStream">
                    <p>và được livestream trên Fanpage <br></br>
                    <strong>Cửa Hàng Trải Nghiệm Samsung</strong> vào lúc <strong>10h - 12h</strong>.</p>
                  </div>

                  <div class="winnerList">
                    <p>Vui lòng kiểm tra danh sách người chơi rút thăm may mắn vào ngày 24/05/2024&nbsp; 
                    <Link to="https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang/download/vong-xoay-quyen-nang.pdf" target="_blank">
                      tại đây
                    </Link>.</p>
                  </div>

                </time> */}

                <div class="finalEvent__t">
                  Chương trình rút thăm may mắn đã chính thức khép lại, <br /><strong>Cửa Hàng Trải Nghiệm Samsung</strong> xin chúc mừng những người chơi đã tham dự và rinh về những phần quà may mắn từ BTC.
                </div>
                <div class="finalEvent__b">
                  <article>
                    Vui lòng kiểm tra danh sách <br class="isMo" /><strong>Mã Dự Thưởng Vòng Chung Kết</strong>{'\u00A0'}
                    <Link to="https://docs.google.com/spreadsheets/d/1PwVghx9QfMwsiiS753aW-08Dc41iTcY4eUewkpB8hvk/edit#gid=0" target="_blank">
                      tại đây
                    </Link> 
                    <br />và <strong>Danh Sách Người Chơi Trúng Thưởng</strong>{'\u00A0'}
                    <Link to="https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang/download/ses2024-final-winner.pdf" target="_blank">
                      tại đây
                    </Link>.
                  </article>
                </div>
              </div>
            </div>

            <div className="welcome__item" data-aos="zoom-in">
              <img src={imgs.homeWheel} alt="" className="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="home">
        <div className="prizes">
          <div className="container">
            <div className="prizes__main">
              <h2 className="title title--secondary" data-aos="fade-down">
                Trao Quyền Năng. Mở Tiềm Năng. Rinh Quà Lớn
              </h2>
              <div className="prizes__body">
                <div className="prizes__item" data-aos="fade-up">
                  <div className="card-prize">
                    <div className="card-prize__thumb">
                      <img src={imgs.galaxyS24} alt="" className="img" />
                    </div>
                    <div className="card-prize__body">
                      <p className="card-prize__title">Giải nhất chung cuộc</p>
                      <p className="card-prize__name">Galaxy S24</p>
                      <p className="card-prize__price">
                        <span>Trị giá: </span> 22.990.000đ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="prizes__item" data-aos="fade-up" data-aos-delay="100">
                  <div className="card-prize">
                    <div className="card-prize__thumb">
                      <img src={imgs.galaxyWatch} alt="" className="img" />
                    </div>
                    <div className="card-prize__body">
                      <p className="card-prize__title">Giải nhì chung cuộc</p>
                      <p className="card-prize__name">Galaxy Watch6</p>
                      <p className="card-prize__price">
                        <span>Trị giá: </span> 6.990.000đ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="prizes__item" data-aos="fade-up" data-aos-delay="200">
                  <div className="card-prize">
                    <div className="card-prize__thumb">
                      <img src={imgs.galaxyBuds} alt="" className="img" />
                    </div>
                    <div className="card-prize__body">
                      <p className="card-prize__title">Giải ba chung cuộc</p>
                      <p className="card-prize__name">Galaxy Buds2 Pro</p>
                      <p className="card-prize__price">
                        <span>Trị giá: </span> 4.990.000đ
                      </p>
                    </div>
                  </div>
                </div>
                <div className="prizes__item" data-aos="fade-up" data-aos-delay="300">
                  <div className="card-prize">
                    <div className="card-prize__thumb">
                      <img src={imgs.galaxyGifts} alt="" className="img" />
                    </div>
                    <div className="card-prize__body">
                      <p className="card-prize__title">Quà may mắn</p>
                      <p className="card-prize__name">
                        Bộ ly thuỷ tinh phiên bản giới hạn, Ly nhựa đổi màu độc quyền hoặc Giá đỡ
                        điện thoại phi hành gia
                      </p>
                      <p className="card-prize__note">
                        <sup>*</sup>Quà thực tế có thể khác hình ảnh mô tả
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prizes__foot">
                <Button
                  withAnimation
                  onClick={() => navigate(staticLinks.luckydraw.url)}
                  className="btn btn--primary"
                  id="playnow_prizesection"
                >
                  Tham gia ngay
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="join" ref={homeCampaignRef}>
          <div className="container">
            <div className="join__main">
              <div className="box-light box-light--type1" data-aos="fade-up">
                <div className="box-light__main">
                  <div className="box-light__body">
                    <div className="join__head">
                      <h2 className="title title--secondary">Cách thức tham gia</h2>
                      <div className="join__desc">
                        Quay càng nhiều, cơ hội trúng thưởng càng lớn. Tham gia ngay hôm nay và tận
                        hưởng hàng ngàn quà tặng độc quyền dành riêng cho bạn!
                      </div>
                    </div>
                    <div className="join__body">
                      <div className="card-join">
                        <div className="card-join__thumb">
                          <img src={imgs.joinWheel} alt="" className="img" />
                        </div>
                        <div className="card-join__body">
                          <h3 className="card-join__title">Bước 1</h3>
                          <p className="card-join__desc">
                            Đăng ký tài khoản và tham gia quay Vòng Xoay Quyền Năng để có cơ hội
                            trúng quà độc quyền và tích luỹ Phiếu dự thưởng.
                          </p>
                        </div>
                      </div>
                      <div className="card-join">
                        <div className="card-join__thumb">
                          <img src={imgs.join2x} alt="" className="img" />
                        </div>
                        <div className="card-join__body">
                          <h3 className="card-join__title">Bước 2</h3>
                          <p className="card-join__desc">
                            Đến cửa hàng trải nghiệm Samsung gần nhất để đổi quà và xác minh Phiếu
                            dự thưởng cùng cơ hội nhân đôi số lượng phiếu dự thưởng.
                          </p>
                        </div>
                      </div>
                      <div className="card-join">
                        <div className="card-join__thumb">
                          <img src={imgs.joinPrize} alt="" className="img" />
                        </div>
                        <div className="card-join__body">
                          <h3 className="card-join__title">Bước 3</h3>
                          <p className="card-join__desc">
                            Theo dõi để biết thông tin người trúng giải thưởng chung cuộc: Galaxy
                            S24, Galaxy Watch6 và Galaxy Buds2 Pro.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="join__foot">
                      <Button
                        onClick={() => navigate(staticLinks.luckydraw.url)}
                        className="btn btn--primary"
                        id="playnow_howtoplay"
                        withAnimation
                      >
                        Tham gia ngay
                      </Button>
                      <Button
                        onClick={() => navigate(staticLinks.termAndConditions.url)}
                        className="btn btn--link"
                        id="tnc"
                        withAnimation
                      >
                        Thể lệ chi tiết
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-light box-light--type3" data-aos="fade-up">
                <div className="box-light__main">
                  <div className="box-light__body">
                    <div className="try-now">
                      <h2 className="title" data-aos="fade-right">
                        Thử ngay Galaxy <span>trên điện thoại của bạn</span>
                      </h2>
                      <div data-aos="zoom-in">
                        <img src={imgs.qrCode} alt="" className="img" />
                      </div>
                      <h3 data-aos="fade-left">Try Galaxy</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
