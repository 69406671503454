import React, { useEffect, useState } from 'react'
import { Button } from './Button'

export const Input = ({
  label,
  type,
  name,
  id,
  placeholder,
  value,
  defaultValue,
  register,
  setValue,
  error,
  onChange,
  onBlur,
  onClick,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleOnChange = (e) => {
    register && register(name).onChange(e)
    onChange && onChange(e)
  }
  const handleOnBlur = (e) => {
    onBlur && onBlur(e)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleOnClick = (e) => {
    onClick && onClick(e)
  }

  useEffect(() => {
    if (value && setValue) setValue(name, value, { shouldValidate: true })
  }, [value, setValue, name])

  return (
    <div className={error ? `field field--invalid` : `field`}>
      {label && (
        <label htmlFor={id} className="field__label">
          {label}
        </label>
      )}
      {type === 'password' ? (
        <div
          className={
            type === 'password' ? 'field__control field__control--password' : 'field__control'
          }
        >
          <input
            type={type === 'password' && showPassword ? 'text' : 'password'}
            name={name}
            id={id}
            className="field__input"
            placeholder={placeholder}
            {...(register && register(name))}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          {type === 'password' && (
            <Button className="btn btn--link" onClick={handleShowPassword} withAnimation>
              <i
                className={
                  type === 'password' && showPassword
                    ? 'icon icon--visibility'
                    : 'icon icon--visibility-off'
                }
              ></i>
            </Button>
          )}
        </div>
      ) : (
        <div className="field__control">
          <input
            type={type}
            name={name}
            id={id}
            className="field__input"
            placeholder={placeholder}
            {...(register && register(name))}
            defaultValue={defaultValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={disabled}
          />
          {name === 'date_of_birth' && (
            <button type="button" onClick={handleOnClick} className="field__birthday"></button>
          )}
        </div>
      )}
      {error && <div className="field__error">{error?.message}</div>}
    </div>
  )
}
