import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Button, Checkbox, Input, Select } from '../../components/elements'
import { globalSelector, setIsLoading, userRegister } from '../../slices'
import { showMessage, staticLinks } from '../../utils'

window.recaptchaOptions = {
  useRecaptchaNet: true,
}

export const RegisterPage = () => {
  const reCaptcha = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { provinceItems } = useSelector(globalSelector)

  const [showCalendar, setShowCalendar] = useState(false)
  const [birthday, setBirthday] = useState('')

  const [message, setMessage] = useState('')
  const [isCaptcha, setIsCaptcha] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Vui lòng nhập địa chỉ email')
      .test('Validate Email', 'Vui lòng nhập đúng định dạng email', (value) => {
        if (value !== '') {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(value).toLowerCase())
        } else {
          return true
        }
      }),
    password: Yup.string()
      .required('Vui lòng nhập mật khẩu')
      .min(6, 'Mật khẩu phải từ 6 ký tự trở lên'),
    confirm_password: Yup.string()
      .required('Vui lòng nhập xác nhận mật khẩu')
      .oneOf([Yup.ref('password'), null], 'Mật khẩu phải trùng khớp'),
    name: Yup.string().required('Vui lòng nhập họ và tên'),
    date_of_birth: Yup.string().required('Vui lòng chọn ngày tháng năm sinh'),
    phone: Yup.string()
      .required('Vui lòng nhập số điện thoại')
      .matches(/(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/, 'Số điện thoại không đúng định dạng'),
    province: Yup.string().required('Vui lòng chọn tỉnh/thành phố'),
    accept_terms: Yup.boolean().oneOf([true]),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm(formOptions)

  const onSubmit = ({ email, password, name, date_of_birth, phone, province }) => {
    const data = {
      email,
      password,
      name,
      date_of_birth,
      phone,
      province,
    }

    if (reCaptcha.current.getValue() === '') {
      setIsCaptcha(false)
      setMessage('Vui lòng nhập captcha')
      return
    } else {
      setIsCaptcha(true)
      setMessage('')

      dispatch(setIsLoading(true))

      userRegister(data)
        .then((response) => {
          const { error_code, error_message } = response
          dispatch(setIsLoading(false))
          if (!error_code) {
            toast(
              'Bạn đã đang ký tài khoản thành công, vui lòng kiểm tra email bạn dùng đăng ký tài khoản & kích hoạt để bắt đầu trải nghiệm.'
            )
            navigate(staticLinks.login.url)
          } else {
            showMessage(error_message, (message) => {
              toast(message)
            })
          }
        })
        .catch((error) => {
          const { error_message } = error
          dispatch(setIsLoading(false))
          showMessage(error_message, (message) => {
            toast(message)
          })
        })
    }
  }

  const handleChange = () => {
    setIsCaptcha(true)
    setMessage('')
  }

  const handleExpired = () => {
    if (reCaptcha.current.getValue() === '') {
      setIsCaptcha(false)
    }
  }

  return (
    <div className="page page--center page--auth">
      <Helmet>
        <title>Đăng ký - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="container">
        <div className="auth auth--register">
          <div className="box-light box-light--type2">
            <div className="box-light__main">
              <div className="box-light__body">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <h2 className="title title--primary text-center">Đăng ký</h2>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Email"
                            name="email"
                            type="email"
                            id="email"
                            register={register}
                            error={errors.email}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12 col-lg-6"></div>
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Mật khẩu"
                            name="password"
                            type="password"
                            id="password"
                            register={register}
                            error={errors.password}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Nhập lại mật khẩu"
                            name="confirm_password"
                            type="password"
                            id="confirm_password"
                            register={register}
                            error={errors.confirm_password}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Họ và tên"
                            name="name"
                            type="text"
                            id="name"
                            register={register}
                            error={errors.name}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Ngày tháng năm sinh"
                            name="date_of_birth"
                            type="text"
                            id="date_of_birth"
                            register={register}
                            error={errors.date_of_birth}
                            setValue={setValue}
                            value={birthday}
                            disabled={true}
                            onClick={() => {
                              setShowCalendar(true)
                            }}
                          />
                        </div>
                        <div className="col col-12 col-lg-6">
                          <Input
                            label="Số điện thoại"
                            name="phone"
                            type="tel"
                            id="phone"
                            register={register}
                            error={errors.phone}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12 col-lg-6">
                          <Select
                            label="Tỉnh/thành phố"
                            placeholder="Tỉnh/thành phố"
                            name="province"
                            id="province"
                            register={register}
                            options={provinceItems}
                            error={errors.province}
                            setValue={setValue}
                          />
                        </div>
                        <div className="col col-12">
                          <Checkbox
                            placeholder={`Tôi đồng ý với&nbsp;<a class="btn btn--link" href=/vong-xoay-quyen-nang${staticLinks.termAndConditions.url}>điều kiện và điều khoản</a>&nbsp;của chương trình`}
                            name="accept_terms"
                            type="checkbox"
                            id="accept_terms"
                            register={register}
                            error={errors.accept_terms}
                            setValue={setValue}
                          />
                        </div>
                      </div>
                      <div className="col col-12">
                        <ReCAPTCHA
                          ref={reCaptcha}
                          className="captcha"
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
                          onChange={handleChange}
                          onExpired={handleExpired}
                          hl="vi"
                        />
                        <span className="error">{message}</span>
                      </div>
                      <div className="mt-3 text-center">
                        <Button
                          type="submit"
                          className="btn btn--primary px-5"
                          id="signup_completed"
                          //disabled={!isValid || !isCaptcha}
                          withAnimation
                        >
                          <span className="btn__text">Đăng ký</span>
                        </Button>
                        <p className="my-3">
                          Bạn đã có tài khoản?{' '}
                          <Button
                            className="btn btn--link"
                            id="signin"
                            type="button"
                            onClick={() => navigate(staticLinks.login.url)}
                            withAnimation
                          >
                            Đăng nhập
                          </Button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={showCalendar ? 'calendar calendar--show' : 'calendar'}>
        <div className="calendar__body">
          <Calendar
            onChange={(value, event) => {
              const newBirthday = moment(value).format('DD-MM-YYYY')
              setBirthday(newBirthday)
              setShowCalendar(false)
            }}
            value={birthday}
            defaultActiveStartDate={moment().subtract(16, 'years').toDate()}
            maxDate={moment().subtract(16, 'years').toDate()}
            locale="vi-VN"
          />
        </div>
      </div>
    </div>
  )
}
