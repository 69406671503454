import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { getName, staticLinks } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { logout, userSelector } from '../../slices'
import { toast } from 'react-toastify'

export const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userData } = useSelector(userSelector)

  const [navToggle, setNavToggle] = useState(false)

  const onResize = useCallback(() => {
    if (window.innerWidth <= 992) {
      if (navToggle) document.querySelector('body').classList.add('no-scroll')
      else document.querySelector('body').classList.remove('no-scroll')
    } else {
      document.querySelector('body').classList.remove('no-scroll')
    }
  }, [navToggle])

  // const location = useLocation();
  // const handleRootNavigation = () => {
  //   const currentPath = location.pathname
  //   console.log(currentPath + ' --  '+currentPath.endsWith('/'))
  //   if (currentPath.endsWith('/')) {
  //     navigate(`${currentPath}/`)
  //   }
  // }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
  }, [onResize])

  // useEffect(() => {
  //   handleRootNavigation()
  // },[])


  return (
    <div className={navToggle ? 'header header--show' : 'header'}>
      <div className="container">
        <div className="header__main">
          <Link to={staticLinks.home.url} className="logo">
            <img src={logo} alt="" className="img" />
          </Link>
          <div className="header__body">
            <ul className="navbar">
              <li className="navbar__item" onClick={() => setNavToggle(false)}>
                <NavLink
                  to={staticLinks.home.url}
                  className={({ isActive }) =>
                    isActive ? 'navbar__link navbar__link--active' : 'navbar__link'
                  }
                  dangerouslySetInnerHTML={{ __html: staticLinks.home.label }}
                  end 
                />
              </li>
              <li className="navbar__item" onClick={() => setNavToggle(false)}>
                <NavLink
                  to={staticLinks.luckydraw.url}
                  className={({ isActive }) =>
                    isActive ? 'navbar__link navbar__link--active' : 'navbar__link'
                  }
                  dangerouslySetInnerHTML={{ __html: staticLinks.luckydraw.label }}
                />
              </li>
              <li className="navbar__item" onClick={() => setNavToggle(false)}>
                <NavLink
                  to={staticLinks.termAndConditions.url}
                  className={({ isActive }) =>
                    isActive ? 'navbar__link navbar__link--active' : 'navbar__link'
                  }
                  dangerouslySetInnerHTML={{ __html: staticLinks.termAndConditions.label }}
                />
              </li>
              <li className="navbar__item" onClick={() => setNavToggle(false)}>
                <NavLink
                  to={staticLinks.storeList.url}
                  className={({ isActive }) =>
                    isActive ? 'navbar__link navbar__link--active' : 'navbar__link'
                  }
                  dangerouslySetInnerHTML={{ __html: staticLinks.storeList.label }}
                />
              </li>
            </ul>
            <ul className="navsign">
              {!userData ? (
                <>
                  <li className="navsign__item" onClick={() => setNavToggle(false)}>
                    <Link to={staticLinks.login.url} className="navsign__link">
                      Đăng nhập
                    </Link>
                  </li>
                  {/* <li
                    className="navsign__item navsign__item--register"
                    onClick={() => setNavToggle(false)}
                  >
                    <Link to={staticLinks.register.url} className="navsign__link">
                      Đăng ký
                    </Link>
                  </li> */}
                </>
              ) : (
                <>
                  <li className="navsign__item" onClick={() => setNavToggle(false)}>
                    <Link to={staticLinks.myProfile.url} className="navsign__link">
                      Xin chào, {getName(userData?.name)} | Trang cá nhân
                    </Link>
                  </li>
                  <li
                    className="navsign__item navsign__item--logout"
                    onClick={() => setNavToggle(false)}
                  >
                    <span
                      onClick={() => {
                        dispatch(logout())
                        toast('Đăng xuất thành công')
                        navigate(staticLinks.home.url)
                      }}
                      className="navsign__link"
                    >
                      Đăng xuất
                    </span>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="nav-toggle" onClick={() => setNavToggle(!navToggle)}>
            <i className={navToggle ? 'icon icon--close' : 'icon icon--burger'}></i>
          </div>
        </div>
      </div>
    </div>
  )
}
