import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { userSelector } from '../slices'
import { staticLinks } from '../utils/staticLinks'

export const AuthRoute = () => {
  const { userData, currentPage } = useSelector(userSelector)

  if (userData) {
    if (currentPage) return <Navigate to={currentPage} />
    else return <Navigate to={staticLinks.home.url} />
  } else return <Outlet />
}
