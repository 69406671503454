import AOS from 'aos'
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Header, Loading, ScrollToTop } from './components/common'
import { Footer } from './components/common/Footer'
import { HomePage, NotFoundPage, StoreListPage, TermsAndConditionsPage } from './pages'
import { ChangePasswordPage, ForgotPasswordPage, LoginPage, RegisterPage } from './pages/auth'
import { LuckyDrawPage, MyProfilePage } from './pages/private'
import { AuthRoute, PrivateRoute, PublicRoute } from './routes'
import { countSpin, fetchDataProvince, globalSelector, userSelector } from './slices'
import { staticLinks } from './utils'

const App = () => {
  const dispatch = useDispatch()
  const { userData } = useSelector(userSelector)
  const { isLoading } = useSelector(globalSelector)

  // useEffect(() => {
  //   if (userData) {
  //     dispatch(countSpin())
  //   }
  // }, [dispatch, userData])

  useEffect(() => {
    dispatch(fetchDataProvince())
  }, [dispatch])

  useEffect(() => {
    AOS.init({
      duration: 700,
      offset: 0,
    })
  }, [])

  return (
    <div className="app">
      <ScrollToTop>
        <Header />
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path={staticLinks.home.url} element={<HomePage />} />
            <Route path={staticLinks.termAndConditions.url} element={<TermsAndConditionsPage />} />
            <Route path={staticLinks.storeList.url} element={<StoreListPage />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route path={staticLinks.login.url} element={<LoginPage />} />
            <Route path={staticLinks.register.url} element={<RegisterPage />} />
            <Route path={staticLinks.forgotPassword.url} element={<ForgotPasswordPage />} />
            <Route path={staticLinks.changePassword.url} element={<ChangePasswordPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={staticLinks.luckydraw.url} element={<LuckyDrawPage />} />
            <Route path={staticLinks.myProfile.url} element={<MyProfilePage />} />
          </Route>
          <Route path={staticLinks.notFound.url} element={<NotFoundPage />} />
        </Routes>
      </ScrollToTop>
      {isLoading && <Loading />}
      <Footer />
      <ToastContainer autoClose={10000} position="top-center" />
    </div>
  )
}

export default App
