import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Pin } from "../../components/elements";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataReward,
  setDataReward,
  setIsLoading,
  updateDataReward,
  userSelector,
} from "../../slices";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { showMessage } from "../../utils";
import { toast } from "react-toastify";

export const MyProfilePage = () => {
  const dispatch = useDispatch();
  const { userData, rewardItems, last_page, current_page } =
    useSelector(userSelector);

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(setIsLoading(true));
    updateDataReward({ page })
      .then((response) => {
        const { error_code } = response;

        if (!error_code) {
          dispatch(setDataReward(response));
        }

        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        const { error_message } = error;
        dispatch(setIsLoading(false));
        showMessage(error_message, (message) => {
          toast(message);
        });
      });
  };

  useEffect(() => {
    dispatch(fetchDataReward({ page: 1 }));
  }, [dispatch]);

  return (
    <div className="page page--center page--auth">
      <Helmet>
        <title>Thông tin cá nhân - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="my-profile">
        <div className="container">
          <div className="box-light box-light--type2" data-aos="fade-up">
            <div className="box-light__main">
              <div className="box-light__body">
                <h2 className="title title--secondary text-center">
                  Thông tin cá nhân
                </h2>
                <div className="row">
                  <div className="col col-12 col-lg-6">
                    <Pin
                      label="Email (Tên đăng nhập)"
                      value={userData?.email}
                    />
                  </div>
                  <div className="col col-12 col-lg-6"></div>
                  <div className="col col-12 col-lg-6">
                    <Pin label="Họ và tên" value={userData?.name} />
                  </div>
                  <div className="col col-12 col-lg-6">
                    <Pin
                      label="Ngày tháng năm sinh"
                      value={moment(userData?.date_of_birth).format(
                        "DD-MM-YYYY"
                      )}
                    />
                  </div>
                  <div className="col col-12 col-lg-6">
                    <Pin label="Số điện thoại" value={userData?.phone} />
                  </div>
                  <div className="col col-12 col-lg-6">
                    <Pin
                      label="Tỉnh thành bạn sinh sống"
                      value={userData?.province_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="box-light box-light--type2"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="box-light__main">
              <div className="box-light__body">
                <h2 className="title title--secondary text-center">
                  Mã quà tặng &amp; dự thưởng
                </h2>
                <div className="my-prize">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Ngày</th>
                          <th>Mã</th>
                          <th>Trạng thái</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rewardItems && rewardItems.length > 0 ? (
                          rewardItems.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {moment(item?.created_at).format("DD/MM/YYYY")}
                              </td>
                              <td>{item?.label === "Ticket" ? ( item?.token ) : ( item?.label ) }</td>
                              <td>
                                {item?.status === "new" && (
                                  <span className="reward-new">
                                    Chưa xác thực{" "}
                                    {item?.label !== "Ticket" ? (
                                      <>
                                        (
                                        <span>
                                          Vui lòng xác thực trước ngày{" "}
                                          { moment(item?.created_at).add(5, 'days').isAfter('2024-05-18') ? 
                                            ( '2024-05-18' ) :
                                            ( moment(item?.created_at).add(5, 'days').format("DD/MM/YYYY") )
                                          }
                                        </span>
                                        )
                                      </>
                                    ) : (
                                      <>
                                        (
                                        <span>
                                          Vui lòng xác thực trước ngày 18/05/2024
                                        </span>
                                        )
                                      </>
                                    )}
                                  </span>
                                )}
                                {item?.status === "confirm" && (
                                  <span className="reward-confirm">
                                    Đã xác thực
                                  </span>
                                )}
                                {item?.status === "reject" && (
                                  <span className="reward-invalid">
                                    Không hợp lệ
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>
                              <p className="text-center mt-3 mb-0">
                                Bạn chưa có quà tặng.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="my-prize__paging">
                    {last_page > 1 && (
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={last_page}
                        forcePage={current_page - 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        pageClassName={"pagination__item"}
                        pageLinkClassName={"pagination__link"}
                        previousClassName={
                          "pagination__item pagination__item--prev"
                        }
                        previousLinkClassName={
                          "pagination__link pagination__link--prev"
                        }
                        nextClassName={
                          "pagination__item pagination__item--next"
                        }
                        nextLinkClassName={
                          "pagination__link pagination__link--next"
                        }
                        breakClassName={
                          "pagination__item pagination__item--break"
                        }
                        breakLinkClassName={
                          "pagination__link pagination__link--break"
                        }
                        activeClassName={"pagination__item--active"}
                        disabledClassName={"pagination__item--disabled"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
