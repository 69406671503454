import React, { memo } from 'react'

export const Checkbox = memo(
  ({ id, name, className, placeholder, checked, register, error, onChange }) => {
    const handleOnChange = (e) => {
      register && register(name).onChange(e)
      onChange && onChange(e)
    }
    return (
      <div className={error ? 'checkbox invalid' : 'checkbox'}>
        <label className="checkbox-label" htmlFor={id}>
          <input
            type="checkbox"
            name={name}
            id={id}
            className={className ? `checkbox-input ${className}` : `checkbox-input`}
            {...(register && register(name))}
            onChange={handleOnChange}
            checked={checked}
          />
          <span className="checkbox-text" dangerouslySetInnerHTML={{ __html: placeholder || '' }} />
        </label>
      </div>
    )
  }
)
