import React from "react";

export const Select = ({
  label,
  placeholder,
  name,
  id,
  register,
  error,
  defaultValue,
  options,
  disabled,
  onChange,
}) => {
  const handleOnChange = (e) => {
    const { value } = e.target;
    const item = options.find((item) => item.id === parseInt(value));
    onChange && onChange(item || null);
  };
  return (
    <div className={error ? `field field--invalid` : `field`}>
      {label && (
        <label htmlFor={id} className="field__label">
          {label}
        </label>
      )}
      <div className="field__control field__control--select">
        <select
          name={name}
          id={id}
          {...(register && register(name))}
          defaultValue={defaultValue}
          className="field__select"
          disabled={disabled}
          onChange={handleOnChange}
        >
          <option value="">{placeholder}</option>
          {options.length > 0 &&
            options.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.fullname || item?.name}
              </option>
            ))}
        </select>
      </div>
      {error && <div className="field__error">{error?.message}</div>}
    </div>
  );
};
