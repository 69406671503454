import React from 'react'
import { Helmet } from 'react-helmet'
import { Button } from '../components/elements'
import { staticLinks } from '../utils'
import { useNavigate } from 'react-router-dom'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <div className="page page--center page--not-found">
      <Helmet>
        <title>404 - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="not-found text-center">
        <h2 className="not-found__title">404</h2>
        <h3 className="not-found__subtitle">Trang bạn tìm kiếm hiện không tồn tại</h3>
        <Button
          className="btn btn--primary px-5"
          onClick={() => navigate(staticLinks.home.url)}
          withAnimation
        >
          Trang chủ
        </Button>
      </div>
    </div>
  )
}
