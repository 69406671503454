import heic2any from 'heic2any'

export const showMessage = (data, callback) => {
  data && Object.values(data).map((value) => callback(value))
}

export const getName = (name) => {
  if (name) {
    return name.split(' ').slice(-1).join(' ')
  } else {
    return ''
  }
}

export const convertImageToBase64 = (file, callback) => {
  fetch(file)
    .then((res) => res.blob())
    .then((blob) =>
      heic2any({
        blob,
        toType: 'image/jpeg',
        quality: 0.5,
      })
    )
    .then((conversionResult) => {
      const reader = new FileReader()
      reader.readAsDataURL(conversionResult)
      reader.onload = () => {
        callback && callback(reader.result)
      }
    })
}
