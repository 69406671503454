import axios from "axios";
import { staticLinks } from "./staticLinks";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    //Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    if (!config.headers["Authorization"]) {
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : null;
      const token = userData?.token;
      if (token) config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data?.resultCode === 10) {
      localStorage.removeItem("userData");
      window.location.href = staticLinks.login.url;
    }
    return response?.data;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userData");
      window.location.href = staticLinks.login.url;
    }
    return Promise.reject(error.response.data);
  }
);
