import React from 'react'

export const Pin = ({ label, value }) => {
  return (
    <div className="pin">
      <p className="pin__label">{label}</p>
      <p className="pin__value">{value}</p>
    </div>
  )
}
