import govLogos1 from './gov-logos-1.png'
import govLogos2 from './gov-logos-2.png'
import govLogos3 from './gov-logos-3.png'
import youtube from './youtube.png'
import facebook from './facebook.png'
import instagram from './instagram.png'
import luckydraw from './luckydraw.png'
import phieuDuThuong from './phieu-du-thuong.png'
import wheelPrize from './wheel-prize.png'
import galaxyBuds from './galaxy-buds.png'
import galaxyGifts from './galaxy-gifts.png'
import galaxyS24 from './galaxy-s24.png'
import galaxyWatch from './galaxy-watch.png'
import homeWheel from './home-wheel.png'
import join2x from './join-2x.png'
import joinPrize from './join-galaxy.png'
import joinWheel from './join-wheel.png'
import qrCode from './qr-code.png'
import lythuytinh from './lythuytinh.png'
import lynhuadoimau from './lynhuadoimau.png'
import kedienthoai from './kedienthoai.png'

const imgs = {
  govLogos1,
  govLogos2,
  govLogos3,
  youtube,
  facebook,
  instagram,
  luckydraw,
  phieuDuThuong,
  wheelPrize,
  galaxyBuds,
  galaxyGifts,
  galaxyS24,
  galaxyWatch,
  homeWheel,
  join2x,
  joinPrize,
  joinWheel,
  qrCode,
  lynhuadoimau,
  lythuytinh,
  kedienthoai,
}

export default imgs
