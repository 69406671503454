import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './slices/globalSlice'
import luckyDrawReducer from './slices/luckyDrawSlice'
import userReducer from './slices/userSlice'

const store = configureStore({
  reducer: {
    global: globalReducer,
    luckyDraw: luckyDrawReducer,
    user: userReducer,
  },
  devTools: process.env.REACT_APP_NODE_ENV === 'development' ? true : false,
})

export default store
