import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { globalApi } from '../apis'

export const fetchDataRule = createAsyncThunk(
  'global/fetchDataRule',
  async (data) => await globalApi.fetchDataRule(data)
)

export const fetchDataWinner = createAsyncThunk(
  'global/fetchDataWinner',
  async (data) => await globalApi.fetchDataWinner(data)
)

export const fetchDataProvince = createAsyncThunk(
  'global/fetchDataProvince',
  async (data) => await globalApi.fetchProvince(data)
)

export const updateDataWinner = async (data) => await globalApi.fetchDataWinner(data)

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    isLoading: false,
    ruleItems: null,
    winnerItems: [],
    provinceItems: [],
    current_page: 1,
    last_page: 3,
    per_page: 32,
    total: 0,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setDataWinner: (state, action) => {
      const {
        error_code,
        data,
        meta: { current_page, last_page, per_page, total },
      } = action.payload
      if (!error_code) {
        state.winnerItems = data
        state.current_page = current_page
        state.last_page = last_page
        state.per_page = per_page
        state.total = total
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchDataRule
      .addCase(fetchDataRule.fulfilled, (state, action) => {
        const { error_code, data } = action.payload
        if (!error_code) state.ruleItems = data
      })
      //fetchDataWinner
      .addCase(fetchDataWinner.fulfilled, (state, action) => {
        const {
          error_code,
          data,
          meta: { current_page, last_page, per_page, total },
        } = action.payload
        if (!error_code) {
          state.winnerItems = data
          state.current_page = current_page
          state.last_page = last_page
          state.per_page = per_page
          state.total = total
        }
      })
      //fetchDataProvince
      .addCase(fetchDataProvince.fulfilled, (state, action) => {
        const { error_code, data } = action.payload
        if (!error_code) {
          state.provinceItems = data
        }
      })
  },
})

export const { setIsLoading, setDataWinner } = globalSlice.actions

export const globalSelector = createSelector(
  (state) => ({
    isLoading: state.global.isLoading,
    ruleItems: state.global.ruleItems,
    winnerItems: state.global.winnerItems,
    current_page: state.global.current_page,
    last_page: state.global.last_page,
    per_page: state.global.per_page,
    total: state.global.total,
    provinceItems: state.global.provinceItems,
  }),
  (state) => state
)

export default globalSlice.reducer
