import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button, Input } from '../../components/elements'
import { changePassword, forgotPassword, setIsLoading, userSelector } from '../../slices'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage, staticLinks } from '../../utils'
import { toast } from 'react-toastify'

export const ChangePasswordPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { email } = useSelector(userSelector)

  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Vui lòng nhập mã OTP'),
    password: Yup.string()
      .required('Vui lòng nhập mật khẩu')
      .min(6, 'Mật khẩu phải từ 6 ký tự trở lên'),
    confirm_password: Yup.string()
      .required('Vui lòng nhập xác nhận mật khẩu')
      .oneOf([Yup.ref('password'), null], 'Mật khẩu phải trùng khớp'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm(formOptions)

  const onSubmit = ({ token, password }) => {
    const data = {
      token,
      password,
    }
    dispatch(setIsLoading(true))
    changePassword(data)
      .then((response) => {
        const { error_code, error_message } = response
        dispatch(setIsLoading(false))
        if (!error_code) {
          navigate(staticLinks.login.url)
          toast('Đổi mật khẩu thành công.')
        } else {
          showMessage(error_message, (message) => {
            toast(message)
          })
        }
      })
      .catch((error) => {
        const { error_message } = error
        dispatch(setIsLoading(false))
        showMessage(error_message, (message) => {
          toast(message)
        })
      })
  }

  const sendOTP = () => {
    const data = {
      email,
    }

    setValue('otp', '', { shouldValidate: true })
    dispatch(setIsLoading(true))

    forgotPassword(data)
      .then((response) => {
        const { error_code, error_message } = response
        dispatch(setIsLoading(false))
        if (!error_code) {
          toast('Vui lòng kiểm tra email để lấy mã xác thực để thực hiện việc thay đổi mật khẩu')
        } else {
          showMessage(error_message, (message) => {
            toast(message)
          })
        }
      })
      .catch(() => {
        dispatch(setIsLoading(false))
        toast('Lỗi hệ thống. Bạn vui lòng thử lại.')
      })
  }

  useEffect(() => {
    //if (!email) navigate(staticLinks.forgotPassword.url)
  }, [email, navigate])

  return (
    <div className="page page--center page--auth">
      <Helmet>
        <title>Thay đổi mật khẩu - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="auth">
        <div className="container">
          <div className="box-light box-light--type2">
            <div className="box-light__main">
              <div className="box-light__body">
                <h2 className="title title--md text-center">Thay đổi mật khẩu</h2>
                <div className="row">
                  <div className="col-md-6 col-lg-4 offset-lg-4 offset-md-3">
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        label="Mã OTP"
                        name="token"
                        type="text"
                        id="token"
                        register={register}
                        error={errors.token}
                        setValue={setValue}
                      />
                      <Input
                        label=" Mật khẩu mới"
                        name="password"
                        type="password"
                        id="password"
                        register={register}
                        error={errors.password}
                        setValue={setValue}
                      />
                      <Input
                        label="Nhập lại mật khẩu mới"
                        name="confirm_password"
                        type="password"
                        id="confirm_password"
                        register={register}
                        error={errors.confirm_password}
                        setValue={setValue}
                      />
                      <Button
                        type="button"
                        onClick={sendOTP}
                        className="btn btn--link"
                        withAnimation
                      >
                        Gửi lại mã OTP
                      </Button>
                      <div className="mt-3 text-center">
                        <Button
                          type="submit"
                          className="btn btn--primary px-5"
                          disabled={!isValid}
                          withAnimation
                        >
                          <span className="btn__text">Gửi</span>
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
