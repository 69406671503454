import { axiosClient } from "../utils";

export const userApi = {
  userLogin: (data) => {
    return axiosClient.post(`/api/player/auth`, data);
  },
  userRegister: (data) => {
    return axiosClient.post(`/api/player/register`, data);
  },
  verifyAccount: (data) => {
    return axiosClient.post(`/api/player/confirm`, data);
  },
  forgotPassword: (data) => {
    return axiosClient.post(`/api/player/forgot-password`, data);
  },
  newPassword: (data) => {
    return axiosClient.post(`/api/player/new-password`, data);
  },
  fetchDataByUser: (data) => {
    return axiosClient.post(`/api/media/checkin/list?page=${data?.page}`, data);
  },
  updateProfile: (data) => {
    return axiosClient.post(`/api/player/confirm-info`, data);
  },
  fetchDataReward: (data) => {
    return axiosClient.get(`/api/play/reward/list?page=${data?.page}`, data);
  },
  fetchStoreList: (data) => {
    return axiosClient.post(`/api/play/reward/store/list`, data);
  },
  updateChooseStore: (data) => {
    return axiosClient.post(`/api/play/reward/store/update`, data);
  },
};
