import { axiosClient } from "../utils";

export const luckyDrawApi = {
  fetchData: () => {
    return axiosClient.get(`/api/play/luckydraw`);
  },
  countSpin: () => {
    return axiosClient.get("/api/play/get_turn");
  },
};
