import { axiosClient } from '../utils'

export const globalApi = {
  fetchDataRule: () => {
    return axiosClient.post(`/api/tnc`)
  },
  fetchDataWinner: (data) => {
    return axiosClient.post(`/api/winner?page=${data?.page}`)
  },
  fetchProvince: () => {
    return axiosClient.post('/api/province')
  },
}
