import React from 'react'
import { Helmet } from 'react-helmet'
// import { usePdf } from '@mikecousins/react-pdf'
// import fileTncOne from '../assets/data/SS_Eureka_T&C.pdf'

export const TermsAndConditionsPage = () => {
  // const [page, setPage] = useState(1)
  // const canvasRef = useRef(null)

  // const { pdfDocument } = usePdf({
  //   file: fileTncOne,
  //   page,
  //   canvasRef,
  //   scale: 1.75,
  // })

  return (
    <div className="page page--rule">
      <Helmet>
        <title>Điều kiện &amp; điều khoản - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="store-list">
        <div className="container">
          <div className="box-light box-light--type2" data-aos="fade-up">
            <div className="box-light__main">
              <div className="box-light__body">
                <h2 className="title title--secondary text-center">Điều kiện &amp; điều khoản</h2>
                <div className="terms_conditions">
                  <h3 className="text-center">
                    CHƯƠNG TRÌNH “Cửa Hàng Trải Nghiệm Samsung. Vòng xoay quyền năng Galaxy AI”
                  </h3>
                  <br />
                  <h5>
                    <strong>I. Giới thiệu chương trình</strong>
                  </h5>
                  <p>
                    Tiếp nối sự kiện ra mắt siêu phẩm công nghệ Galaxy AI “Trao Quyền Năng, Mở Tiềm
                    Năng”, Cửa Hàng Trải Nghiệm Samsung hân hạnh mang đến chương trình{' '}
                    <strong>Vòng Xoay Quyền Năng Galaxy AI</strong> (sau đây gọi tắt là “
                    <strong>Chương trình</strong>”), nơi người chơi không chỉ được tham gia trải
                    nghiệm công nghệ đột phá mà còn có cơ hội nhận được hàng ngàn quà tặng độc quyền
                    và giải thưởng chung cuộc hấp dẫn.
                  </p>
                  <p>
                    Lấy cảm hứng từ Samsung Galaxy S24, một siêu phẩm công nghệ AI trao quyền năng
                    công nghệ mạnh mẽ cho người chơi ứng dụng vào mọi lĩnh vực tiềm năng trong tương
                    lai.
                  </p>
                  <p>
                    Cửa Hàng Trải Nghiệm Samsung thiết kế trò chơi khác với những chương trình vòng
                    quay may mắn thông thường. Thay vì chỉ có một lá thăm duy nhất, Cửa Hàng Trải
                    Nghiệm Samsung trao cho người chơi những quyền năng đặc biệt như tích lũy phiếu
                    dự thưởng hàng ngày. Nhờ đó, mỗi cá nhân có thể mở ra thêm nhiều cơ hội và tiềm
                    năng chiến thắng.
                  </p>
                  <p>
                    Đã sẵn sàng đến với Vòng Xoay Tiềm Năng của Cửa Hàng Trải Nghiệm Samsung? Mời
                    bạn khám phá nội dung cùng điều kiện và thể lệ chi tiết của cuộc thi.
                  </p>
                  <h5>
                    <strong>II. Nội dung chương trình</strong>
                  </h5>
                  <p>
                    <strong>1. Đối tượng tham gia:</strong> Công dân mang quốc tịch Việt Nam, có độ
                    tuổi từ 18 đến 55 tuổi, hiện đang cư trú trên phạm vi lãnh thổ Việt Nam.
                  </p>
                  <p>
                    <strong>2. Phạm vi tổ chức:</strong> Chương trình được triển khai tại các tỉnh
                    thành có Cửa Hàng Trải Nghiệm Samsung bao gồm:{' '}
                    <strong>
                      {' '}
                      An Giang, Bắc Giang, Bạc Liêu, Bắc Ninh, Bến Tre, Bình Định, Bình Dương, Đà
                      Nẵng, Đồng Nai, Hà Nội, Hải Phòng, Hồ Chí Minh, Kiên Giang, Nam Định, Nghệ An,
                      Nha Trang, Thanh Hoá.
                    </strong>
                  </p>
                  <p>
                    <strong>3. Thời gian chương trình</strong>
                  </p>
                  <p>
                    Chương trình bắt đầu từ ngày <strong>12/03/2024</strong> đến{' '}
                    <strong>09/05/2024</strong>, hoặc cho đến khi hết quà tặng, tùy thời điểm nào
                    đến trước.
                  </p>
                  <p>
                    <strong>4. Thể lệ tham gia:</strong>
                  </p>
                  <p>Người chơi tham gia cuộc thi theo các bước sau:</p>
                  <p>
                    BƯỚC 1: Truy cập website:{' '}
                    <a href="https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang">
                      https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang
                    </a>{' '}
                    và đăng ký tạo tài khoản trước khi chơi.
                  </p>
                  <p>BƯỚC 2: Người chơi nhấn Quay tại Vòng Xoay Quyền Năng để nhận được kết quả.</p>
                  <ul>
                    <li>
                      Nếu người chơi quay được kết quả là{' '}
                      <strong>Bộ ly thuỷ tinh phiên bản giới hạn</strong> thì:
                      <ul>
                        <li>
                          Người chơi cần mang email thể hiện kết quả Bộ ly thuỷ tinh phiên bản giới
                          hạn có chứa mã gồm 16 kí tự (chữ và số) đến địa chỉ Cửa Hàng Trải Nghiệm
                          Samsung được chỉ định trong email để liên hệ đổi quà. Yêu cầu đến cửa hàng
                          đổi quà trước thời gian quy định được đề cập trong email.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Nếu người chơi quay được kết quả là <strong>Ly nhựa đổi màu độc quyền</strong>{' '}
                      thì:
                      <ul>
                        <li>
                          Người chơi cần mang email thể hiện kết quả Giá đỡ điện thoại phi hành gia
                          có chứa mã gồm 16 kí tự (chữ và số) đến địa chỉ Cửa Hàng Trải Nghiệm
                          Samsung được chỉ định trong email để liên hệ đổi quà. Yêu cầu đến cửa hàng
                          đổi quà trước thời gian quy định được đề cập trong email.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Nếu người chơi quay được kết quả là{' '}
                      <strong>Giá đỡ điện thoại phi hành gia</strong> thì:
                      <ul>
                        <li>
                          Người chơi cần mang email thể hiện kết quả Bộ ly thuỷ tinh phiên bản giới
                          hạn có chứa mã gồm 16 kí tự (chữ và số) đến địa chỉ Cửa Hàng Trải Nghiệm
                          Samsung được chỉ định trong email để liên hệ đổi quà. Yêu cầu đến cửa hàng
                          đổi quà trước thời gian quy định được đề cập trong email.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Nếu người chơi quay được kết quả là <strong>Phiếu dự thưởng</strong> thì:
                      <ul>
                        <li>
                          Người chơi cần mang email thể hiện kết quả Phiếu dự thưởng có chứa mã gồm
                          16 kí tự (chữ và số) đến địa chỉ Cửa Hàng Trải Nghiệm Samsung (theo danh
                          sách quy định) để xác minh phiếu dự thưởng là hợp lệ.
                        </li>
                        <li>
                          Phiếu dự thưởng chưa được xác minh tại Cửa Hàng Trải Nghiệm Samsung là
                          không hợp lệ và không có hiệu lực tham gia vòng quay may mắn chung cuộc.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>*Mỗi tài khoản người chơi nhận được 1 lượt quay / ngày.</p>
                  <p>
                    <strong>5. Giải thưởng:</strong>
                  </p>
                  <ul>
                    <li>Bộ ly thuỷ tinh phiên bản giới hạn: Tổng số lượng 2,400 phần quà</li>
                    <li>Ly nhựa đổi màu độc quyền: Tổng số lượng 2,400 phần quà</li>
                    <li>Giá đỡ điện thoại phi hành gia: Tổng số lượng 2,400 phần quà</li>
                  </ul>
                  <p>
                    *Tại thời điểm người chơi đến cửa hàng để nhận quà, Trong trường hợp{' '}
                    <strong>Bộ ly thuỷ tinh phiên bản giới hạn</strong> hết hàng, Cửa Hàng Trải
                    Nghiệm Samsung có quyền đổi quà tặng thành{' '}
                    <strong>Ly nhựa đổi màu độc quyền</strong> hoặc{' '}
                    <strong>Giá đỡ điện thoại phi hành gia.</strong>
                  </p>
                  <p>
                    *Tại thời điểm người chơi đến cửa hàng để nhận quà, Trong trường hợp{' '}
                    <strong>Ly nhựa đổi màu độc quyền hết hàng</strong>, Cửa Hàng Trải Nghiệm
                    Samsung có quyền đổi quà tặng thành{' '}
                    <strong>Bộ ly thuỷ tinh phiên bản giới hạn</strong> hoặc{' '}
                    <strong>Giá đỡ điện thoại phi hành gia</strong>.
                  </p>
                  <p>
                    *Tại thời điểm người chơi đến cửa hàng để nhận quà, Trong trường hợp{' '}
                    <strong>Giá đỡ điện thoại phi hành gia</strong> hết hàng, Cửa Hàng Trải Nghiệm
                    Samsung có quyền đổi quà tặng thành{' '}
                    <strong>Bộ ly thuỷ tinh phiên bản giới hạn</strong> hoặc{' '}
                    <strong>Ly nhựa đổi màu độc quyền</strong>.
                  </p>
                  <p>
                    Xin lưu ý: Do số lượng quà tặng có giới hạn, người chơi tham gia chương trình
                    sau có thể không đủ điều kiện tham gia chương trình nếu hết số lượng quà tặng.
                    Người được thông báo thắng giải cần xác nhận thông tin cá nhân bằng cách cung
                    cấp địa chỉ nhận giải và xuất trình chứng minh nhân dân (“<strong>CMND</strong>
                    ”) hoặc căn cước công dân (“<strong>CCCD</strong>”) hoặc hộ chiếu trùng họ tên
                    và ngày tháng năm sinh đã đăng ký để xác thực khi nhận giải thưởng. Mỗi tài
                    khoản chỉ được sử dụng 1 CMND hoặc CCCD khớp thông tin đã đăng ký.
                  </p>
                  <p>GIải thưởng chung cuộc:</p>
                  <p>- Giải nhất: 01 Samsung Galaxy S24 - 256GB/8GB (màu bất kỳ)</p>
                  <p>- Giải nhì: 01 Samsung Watch6 44mm (màu bất kỳ)</p>
                  <p>- Giải ba: 01 Samsung Buds2 Pro (màu bất kỳ)</p>
                  <p>
                    <strong>6. Thời gian công bố và trao giải thưởng chung cuộc:</strong>
                  </p>
                  <p>
                    Toàn bộ người chơi có phiếu dự thưởng hợp lệ sẽ có cơ hội rút thăm trúng thưởng,
                    danh sách người được dự rút thăm giải chung cuộc và địa điểm tổ chức chương
                    trình rút thăm may mắn sẽ được công bố trên trang web:{' '}
                    <a href="https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang">
                      https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang
                    </a>{' '}
                    vào ngày 15/05/2024.
                  </p>
                  <p>
                    Chương trình rút thăm may mắn sẽ được tổ chức tại: Cửa Hàng Trải Nghiệm Samsung
                    - T-07 Tầng 1 Trung Tâm Thương Mại Gigamall Phạm Văn Đồng, 240-242 đường Kha Vạn
                    Cân, Phường Hiệp Bình Chánh, Tp. Thủ Đức vào ngày <strong>21/05/2024</strong>.
                  </p>
                  <p>
                    Ban Tổ Chức sẽ thông báo địa điểm tổ chức chương trình rút thăm may mắn, và các
                    khách mời bao gồm: 03 người chơi, Cơ quan chính quyền, đại diện Samsung và Cheil
                    vào ngày 15/05/2024.
                  </p>
                  <h5>
                    <strong>III. CÁCH THỨC CÔNG BỐ VÀ TRAO THƯỞNG:</strong>
                  </h5>
                  <p>
                    <strong>1. Cách thức công bố:</strong>
                  </p>
                  <p>
                    Danh sách thắng giải thưởng chung cuộc sẽ được công bố công khai trên website
                    chính thức của chương trình:{' '}
                    <a href="https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang">
                      https://cuahangtrainghiemsamsung.com/vong-xoay-quyen-nang
                    </a>{' '}
                    theo thời gian tại điều 6 trong bảng điều khoản và điều kiện này.
                  </p>
                  <p>
                    <strong>2. Cách thức nhận thưởng</strong>
                  </p>
                  <p>
                    - Công ty TNHH Samsung Electronics Việt Nam Thái Nguyên – Chi nhánh Thành phố Hồ
                    Chí Minh, địa chỉ: Tầng 25, Tòa nhà Bitexco Financial, Số 02 Hải Triều, Phường
                    Bến Nghé, Quận 1, TP. Hồ Chí Minh (sau đây gọi tắt là <strong>“Samsung”</strong>{' '}
                    hoặc <strong>“Samsung Việt Nam”</strong>) sẽ tiến hành trao giải cho người thắng
                    giải thưởng chung cuộc theo thời gian đã nhắc đến tại điều 6 trong bảng điều
                    khoản và điều kiện này.
                  </p>
                  <p>
                    Người được thông báo thắng giải cần xác nhận thông tin cá nhân bằng cách cung
                    cấp địa chỉ nhận giải và xuất trình chứng minh nhân dân (<strong>“CMND”</strong>
                    ) hoặc căn cước công dân (<strong>“CCCD”</strong>) hoặc hộ chiếu trùng họ tên và
                    ngày tháng năm sinh đã đăng ký để xác thực khi nhận giải thưởng. Nếu trong 72
                    giờ kể từ khi kết quả chính thức được công bố, Ban tổ chức (bao gồm Samsung và
                    Công ty TNHH Cheil Việt Nam, địa chỉ: Tầng 17, Tòa nhà Tài chính Bitexco, Số 02
                    Hải Triều, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh) (sau đây gọi tắt là{' '}
                    <strong>“BTC”</strong>) không nhận được xác nhận từ người thắng giải, đồng nghĩa
                    với việc người thắng giải đơn phương từ chối giải thưởng.
                  </p>
                  <p>
                    - BTC có quyền từ chối trao quà cho người thắng giải không thỏa các điều kiện
                    nhận giải nêu trên và không có trách nhiệm giải quyết bất kỳ tranh chấp nào có
                    liên quan.
                  </p>
                  <p>
                    - BTC và Samsung sẽ không chịu trách nhiệm trong trường hợp người trúng giải
                    không nhận được giải thưởng do không cung cấp được hoặc cung cấp sai lệch các
                    thông tin do BTC yêu cầu.
                  </p>
                  <h5>
                    <strong>IV. CÁC QUY ĐỊNH KHÁC</strong>
                  </h5>
                  <p>
                    - Người chơi phải chịu trách nhiệm về tính xác thực của thông tin mà người chơi
                    đã cung cấp cho BTC. Nếu người chơi cung cấp cho BTC thông tin không đúng sự
                    thật, người chơi đó sẽ bị loại hoặc tước giải thưởng ở bất kỳ giai đoạn nào của
                    chương trình, kể cả sau khi đã trao giải thưởng.
                  </p>
                  <p>
                    - Mọi thư khiếu nại, tố cáo về cuộc thi sẽ được giải quyết theo các quy định của
                    BTC. Nếu người chơi có hành vi gian lận, hoặc trong trường hợp có đơn thư khiếu
                    nại, tố cáo gửi đến cuộc thi thì BTC có quyền loại lượt chơi của người chơi đó
                    mà không cần thông báo.
                  </p>
                  <p>
                    - Cuộc thi diễn ra tuân thủ nghiêm chỉnh các quy định của pháp luật Việt Nam.
                    BTC giữ quyền phán quyết cao nhất về giải thưởng cũng như quyền kiểm tra tư cách
                    người chơi theo quy định của cuộc thi.
                  </p>
                  <p>
                    - Những người chơi trúng giải phải gửi lại thông tin yêu cầu để xác nhận qua
                    email thông báo từ BTC và xuất trình CMND hoặc CCCD hoặc hộ chiếu với BTC để xác
                    nhận thông tin cá nhân khi nhận giải.
                  </p>
                  <p>
                    - Bằng việc tham gia Chương trình này, người chơi đồng ý cho phép BTC và đơn vị
                    đồng hành Samsung Việt Nam sử dụng hình ảnh của người chơi.
                  </p>
                  <p>
                    - Bằng việc tham gia Chương trình này, người chơi đồng ý cho phép Samsung sử
                    dụng các thông tin cá nhân bao gồm tên, số điện thoại/ email/ tên tài khoản,
                    hình ảnh (nếu có) cho mục đích công bố người nhận giải thưởng và mục đích truyền
                    thông, quảng cáo mà không phải trả thêm bất kỳ khoản chi phí nào. Đồng thời,
                    việc sử dụng thông tin cá nhân của người chơi sẽ được tuân theo Chính sách bảo
                    mật thông tin của Tập đoàn Samsung và pháp luật Việt Nam.
                  </p>
                  <p>
                    - Nhân viên của Samsung và của các công ty liên kết, các công ty cung cấp dịch
                    vụ quảng cáo và khuyến mại của Samsung và các nhân viên của các công ty này, các
                    thành viên trong gia đình và/hoặc những người sống trong cùng một hộ gia đình
                    của các nhân viên như được liệt kê ở trên sẽ không được tham gia Cuộc thi này.
                  </p>
                  <p>CHÚC CÁC BẠN MAY MẮN VÀ NHẬN ĐƯỢC NHỮNG PHẦN QUÀ HẤP DẪN TỪ SAMSUNG NHÉ!</p>
                </div>

                {/* <div className="view-pdf">
                  {!pdfDocument && <span className="view-pdf__loading">Đang tải...</span>}
                  <canvas ref={canvasRef} />
                  {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <div className="pager">
                      <button
                        className={
                          page === 1
                            ? 'pager__item pager__item--prev pager__item--disabled'
                            : 'pager__item pager__item--prev'
                        }
                        onClick={() => {
                          setPage(page - 1)
                        }}
                      >
                        Quay lại
                      </button>
                      <button
                        className={
                          page === pdfDocument.numPages
                            ? 'pager__item pager__item--next pager__item--disabled'
                            : 'pager__item pager__item--next'
                        }
                        onClick={() => {
                          setPage(page + 1)
                        }}
                      >
                        Tiếp theo
                      </button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
