import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { userSelector } from '../slices'
import { staticLinks } from '../utils/staticLinks'

export const PrivateRoute = () => {
  const { userData } = useSelector(userSelector)

  if (!userData) return <Navigate to={staticLinks.login.url} />
  else return <Outlet />
}
