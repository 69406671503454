import React, { useRef } from 'react'

export const Button = ({
  children,
  type,
  className,
  id,
  disabled,
  onClick,
  href,
  target,
  withAnimation,
}) => {
  const buttonRef = useRef(null)

  const handleClick = (e) => {
    if (!buttonRef.current || disabled) return
    buttonRef.current.classList.add('btn--animate')
    setTimeout(() => {
      if (buttonRef.current) buttonRef.current.classList.remove('btn--animate')
    }, 300)
    if (onClick) onClick(e)
  }

  return (
    <>
      {type === 'link' ? (
        <a
          ref={buttonRef}
          type={type}
          className={className}
          id={id}
          onClick={withAnimation ? handleClick : onClick}
          href={href}
          target={target}
          disabled={disabled}
        >
          {children}
        </a>
      ) : type === 'button' || type === 'submit' ? (
        <button
          ref={buttonRef}
          type={type}
          className={className}
          id={id}
          onClick={withAnimation ? handleClick : onClick}
          disabled={disabled}
        >
          {children}
        </button>
      ) : (
        <div
          ref={buttonRef}
          type={type}
          className={className}
          id={id}
          onClick={withAnimation ? handleClick : onClick}
          disabled={disabled}
        >
          {children}
        </div>
      )}
    </>
  )
}
