import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { luckyDrawApi } from '../apis/luckyDrawApi'

export const fetchDataLuckyDraw = async (data) => await luckyDrawApi.fetchData(data)

export const checkSpin = async (data) => await luckyDrawApi.countSpin(data)

export const countSpin = createAsyncThunk(
  'luckyDraw/countSpin',
  async (data) => await luckyDrawApi.countSpin(data)
)

const luckyDrawSlice = createSlice({
  name: 'luckyDraw',
  initialState: {
    prizeItem: null,
    remain: 0,
    total: 0,
  },
  reducers: {
    setSpin: (state, action) => {
      const { remain, total } = action.payload

      state.remain = remain
      state.total = total
    },
    setPrizeItem: (state, action) => {
      state.prizeItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //countSpin
      .addCase(countSpin.fulfilled, (state, action) => {
        const {
          data: { remain, total },
        } = action.payload

        state.remain = remain
        state.total = total
      })
  },
})

export const { setSpin, setPrizeItem } = luckyDrawSlice.actions

export const luckyDrawSelector = createSelector(
  (state) => ({
    prizeItem: state.luckyDraw.prizeItem,
    total: state.luckyDraw.total,
    remain: state.luckyDraw.remain,
  }),
  (state) => state
)

export default luckyDrawSlice.reducer
