import React from 'react'
import { Helmet } from 'react-helmet'

export const StoreListPage = () => {
  return (
    <div className="page page--rule">
      <Helmet>
        <title>Danh sách cửa hàng - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="store-list">
        <div className="container">
          <div className="box-light box-light--type2" data-aos="fade-up">
            <div className="box-light__main">
              <div className="box-light__body">
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2">
                    <h2 className="title title--primary text-center">Danh sách cửa hàng</h2>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Số thứ tự</th>
                            <th>Tỉnh/Thành phố</th>
                            <th>Địa chỉ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>An Giang</td>
                            <td>209 Trần Hưng Đạo, P. Mỹ Bình, Tp. Long Xuyên, An Giang</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Bắc Giang</td>
                            <td>226 Hoàng Văn Thụ, P. Hoàng Văn Thụ, Tp. Bắc Giang, Bắc Giang</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Bạc Liêu</td>
                            <td>45 Trần Huỳnh, Khóm 2, P. 7, Tp. Bạc Liêu, Bạc Liêu</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Bắc Ninh</td>
                            <td>109 Trần Hưng Đạo, P. Đại Phúc, Tp. Bắc Ninh, Bắc Ninh</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Bắc Ninh</td>
                            <td>360-362 Trần Phú, P. Đình Bảng, Tp. Từ Sơn, Bắc Ninh</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Bắc Ninh</td>
                            <td>10B Nguyễn Đăng Đạo, P. Tiền An, Tp. Bắc Ninh, Bắc Ninh</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Bến Tre</td>
                            <td>Thị Trấn Thạnh Phú, Huyện Thạnh Phú, Bến Tre</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Bình Định</td>
                            <td>300 Phan Bội Châu, P. Trần Hưng Đạo, Tp. Quy Nhơn, Bình Định</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Bình Dương</td>
                            <td>
                              459 Đại Lộ Bình Dương, P. Phú Cường, Tp. Thủ Dầu Một, Bình Dương
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Bình Dương</td>
                            <td>229K Lý Thường Kiệt, P. Dĩ An, Tp. Dĩ An, Bình Dương</td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>Bình Dương</td>
                            <td>145/4b Phan Đình Phùng, P. Lái Thiêu, Tp. Thuận An, Bình Dương</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>Bình Dương</td>
                            <td>139/1 Khu phố 1A, P. An Phú, Tp. Thuận An, Bình Dương</td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>Bình Dương</td>
                            <td>27/17 KP Bình Phước B, P. Bình Chuẩn, Tp.Thuận An, Bình Dương</td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>Bình Dương</td>
                            <td>Lô A9-10, Quốc lộ 13, P. Mỹ Phước, Thị Xã Bến Cát, Bình Dương</td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td>Đà Nẵng</td>
                            <td>87 Nguyễn Văn Linh, P. Nam Dương, Q. Hải Châu, Tp. Đà Nẵng</td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>Đồng Nai</td>
                            <td>91 Đồng Khởi, P. Tam Hiệp, Tp. Biên Hòa, Đồng Nai</td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>Đồng Nai</td>
                            <td>48 Ba Mươi Tháng Tư, P. Trung Dũng, Tp. Biên Hòa, Đồng Nai</td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td>Đồng Nai</td>
                            <td>1176 Phạm Văn Thuận, P. Tân Tiến, Tp. Biên Hòa, Đồng Nai</td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Hà Nội</td>
                            <td>98 Thái Hà, P. Trung Liệt, Q. Đống Đa, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Hà Nội</td>
                            <td>15-17 Thái Hà, P. Trung Liệt, Q. Đống Đa, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>21</td>
                            <td>Hà Nội</td>
                            <td>535 Nguyễn Trãi, P. Thanh Xuân Nam, Q. Thanh Xuân, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td>Hà Nội</td>
                            <td>172-174 Nguyễn Văn Cừ, P. Bồ Đề, Q. Long Biên, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td>Hà Nội</td>
                            <td>24-26 Bạch Mai, P. Cầu Dền, Q. Hai Bà Trưng, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>Hà Nội</td>
                            <td>191 Minh Khai, P. Minh Khai, Q. Hai Bà Trưng, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>25</td>
                            <td>Hà Nội</td>
                            <td>53C Hàng Bài, P. Hàng Bài, Q. Hoàn Kiếm, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>26</td>
                            <td>Hà Nội</td>
                            <td>438 Xã Đàn, P. Nam Đồng, Q. Đống Đa, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>27</td>
                            <td>Hà Nội</td>
                            <td>26 Trần Phú, P. Mộ Lao, Q. Hà Đông, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>28</td>
                            <td>Hà Nội</td>
                            <td>216 Thái Hà, P. Trung Liệt, Q. Đống Đa, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>29</td>
                            <td>Hà Nội</td>
                            <td>45 Trần Duy Hưng, P. Trung Hòa, Q. Cầu Giấy, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>30</td>
                            <td>Hà Nội</td>
                            <td>32 Cầu Bươu, Xã Tân Triều, Huyện Thanh Trì, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>31</td>
                            <td>Hà Nội</td>
                            <td>275 Hồ Tùng Mậu, P. Cầu Diễn, Q. Nam Từ Liêm, Hà Nội</td>
                          </tr>
                          <tr>
                            <td>32</td>
                            <td>Hải Phòng</td>
                            <td>
                              609 Ngô Gia Tự, Tổ dân phố Trung Hành 9, P. Đằng Lâm, Q.Hải An, Tp.Hải
                              Phòng
                            </td>
                          </tr>
                          <tr>
                            <td>33</td>
                            <td>Hải Phòng</td>
                            <td>120 Trần Phú, P. Cầu Đất, Q. Ngô Quyền, Tp. Hải Phòng</td>
                          </tr>
                          <tr>
                            <td>34</td>
                            <td>Hồ Chí Minh</td>
                            <td>
                              Tầng 3, TTTM Crescent Mall, 101 Tôn Dật Tiên, Q. 7, Tp. Hồ Chí Minh
                            </td>
                          </tr>
                          <tr>
                            <td>35</td>
                            <td>Hồ Chí Minh</td>
                            <td>149F Trần Quang Khải, P. Tân Định, Q. 1, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>36</td>
                            <td>Hồ Chí Minh</td>
                            <td>134 Nguyễn Thái Học, P. Phạm Ngũ Lão, Q. 1, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>37</td>
                            <td>Hồ Chí Minh</td>
                            <td>999 Lũy Bán Bích, P. Tân Thành, Q. Tân Phú, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>38</td>
                            <td>Hồ Chí Minh</td>
                            <td>447 Nguyễn Văn Luông, P. 12, Q. 6, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>39</td>
                            <td>Hồ Chí Minh</td>
                            <td>39 Trường Chinh, P. Tân Thới Nhất, Q. 12, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Hồ Chí Minh</td>
                            <td>481 Nguyễn Thị Thập, P. Tân Phong, Q. 7, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>41</td>
                            <td>Hồ Chí Minh</td>
                            <td>199 Võ Văn Ngân, P. Linh Chiểu, Tp. Thủ Đức</td>
                          </tr>
                          <tr>
                            <td>42</td>
                            <td>Hồ Chí Minh</td>
                            <td>91B Cách Mạng Tháng Tám, P. Bến Thành, Q. 1, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>43</td>
                            <td>Hồ Chí Minh</td>
                            <td>861 Quang Trung, P. 8, Q. Gò Vấp, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>44</td>
                            <td>Hồ Chí Minh</td>
                            <td>134 Trần Phú, P. 4, Q. 5, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>45</td>
                            <td>Hồ Chí Minh</td>
                            <td>203 Nguyễn Thái Sơn, P. 7, Q. Gò Vấp, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>46</td>
                            <td>Hồ Chí Minh</td>
                            <td>38 Nguyễn Thị Định, P. An Phú, Q. 2, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>47</td>
                            <td>Hồ Chí Minh</td>
                            <td>195 Khánh Hội, P. 3, Q. 4, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>48</td>
                            <td>Hồ Chí Minh</td>
                            <td>55A Nguyễn Ảnh Thủ, P. Trung Mỹ Tây, Q. 12, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>49</td>
                            <td>Hồ Chí Minh</td>
                            <td>182 Trần Bình Trọng, P. 3, Quận 5, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>50</td>
                            <td>Hồ Chí Minh</td>
                            <td>29 Âu Cơ, P. 14, Q. 11, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>51</td>
                            <td>Hồ Chí Minh</td>
                            <td>694 Tỉnh lộ 10, P. Bình Trị Đông, Q. Bình Tân, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>52</td>
                            <td>Hồ Chí Minh</td>
                            <td>
                              A13/23 Quốc lộ 50, Xã Bình Hưng, Huyện Bình Chánh, Tp. Hồ Chí Minh
                            </td>
                          </tr>
                          <tr>
                            <td>53</td>
                            <td>Hồ Chí Minh</td>
                            <td>114 Lê Văn Việt, P. Hiệp Phú, Tp. Thủ Đức</td>
                          </tr>
                          <tr>
                            <td>54</td>
                            <td>Hồ Chí Minh</td>
                            <td>14 Hoàng Hoa Thám, P. 12, Q. Tân Bình, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>55</td>
                            <td>Hồ Chí Minh</td>
                            <td>84 Tỉnh lộ 8, Xã Tân Thạnh Tây, Huyện Củ Chi, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>56</td>
                            <td>Hồ Chí Minh</td>
                            <td>
                              TTTM AEON Mall, 30 Bờ Bao Tân Thắng, P. Sơn Kỳ, Q. Tân Phú, Tp. Hồ Chí
                              Minh
                            </td>
                          </tr>
                          <tr>
                            <td>57</td>
                            <td>Hồ Chí Minh</td>
                            <td>577 Dương Bá Trạc, P. 1, Q. 8, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>58</td>
                            <td>Hồ Chí Minh</td>
                            <td>37B Trần Quang Khải, P. Tân Định Q. 1, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>59</td>
                            <td>Hồ Chí Minh</td>
                            <td>215-217 Trần Hưng Đạo, P. Cô Giang, Q. 1, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>60</td>
                            <td>Hồ Chí Minh</td>
                            <td>465 Kha Vạn Cân, Phường Linh Đông, Tp. Thủ Đức</td>
                          </tr>
                          <tr>
                            <td>61</td>
                            <td>Hồ Chí Minh</td>
                            <td>506 Cách Mạng Tháng Tám, P. 11, Q. 3, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>62</td>
                            <td>Hồ Chí Minh</td>
                            <td>696 Âu Cơ, P. 14, Q. Tân Bình, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>63</td>
                            <td>Hồ Chí Minh</td>
                            <td>1031 Hậu Giang, P. 10, Q. 6, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>64</td>
                            <td>Hồ Chí Minh</td>
                            <td>739 Kinh Dương Vương, P. An Lạc, Q. Tân Bình, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>65</td>
                            <td>Hồ Chí Minh</td>
                            <td>155-157 Liên Tỉnh 5, P. 5, Q. 8, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>66</td>
                            <td>Hồ Chí Minh</td>
                            <td>94-96 Quang Trung, P. 10, Q. Gò Vấp, Tp. Hồ Chí Minh</td>
                          </tr>
                          <tr>
                            <td>67</td>
                            <td>Hồ Chí Minh</td>
                            <td>
                              Tầng 1, TTTM Giga Mall, 242, Kha Vạn Cân, P. Hiệp Bình Chánh, Tp. Thủ
                              Đức
                            </td>
                          </tr>
                          <tr>
                            <td>68</td>
                            <td>Kiên Giang</td>
                            <td>570 Nguyễn Trung Trực, P. Vĩnh Lạc, Tp. Rạch Giá, Kiên Giang</td>
                          </tr>
                          <tr>
                            <td>69</td>
                            <td>Kiên Giang</td>
                            <td>D30-31 Ba Tháng Hai, P. Vĩnh Lạc, Tp. Rạch Giá, Kiên Giang</td>
                          </tr>
                          <tr>
                            <td>70</td>
                            <td>Nam Định</td>
                            <td>184 Trần Hưng Đạo, P. Bà Triệu, Tp. Nam Định, Nam Định</td>
                          </tr>
                          <tr>
                            <td>71</td>
                            <td>Nghệ An</td>
                            <td>85 Nguyễn Thị Minh Khai, P. Hưng Bình, Tp. Vinh, Nghệ An</td>
                          </tr>
                          <tr>
                            <td>72</td>
                            <td>Nha Trang</td>
                            <td>236 Thống Nhất, P. Phương Sơn, Tp. Nha Trang, Khánh Hòa</td>
                          </tr>
                          <tr>
                            <td>73</td>
                            <td>Thanh Hóa</td>
                            <td>125 Trần Phú, P. Lam Sơn, Tp. Thanh Hóa, Thanh Hóa</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
