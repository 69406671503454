import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button, Input } from '../../components/elements'
import { showMessage, staticLinks } from '../../utils'
import { useNavigate } from 'react-router-dom'
import { forgotPassword, setEmail, setIsLoading } from '../../slices'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

export const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Vui lòng nhập địa chỉ email')
      .test('Validate Email', 'Vui lòng nhập đúng định dạng email', (value) => {
        if (value !== '') {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(value).toLowerCase())
        } else {
          return true
        }
      }),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm(formOptions)

  const onSubmit = ({ email }) => {
    const data = {
      email,
    }

    dispatch(setIsLoading(true))

    forgotPassword(data)
      .then((response) => {
        const { error_code, error_message } = response
        dispatch(setIsLoading(false))
        if (!error_code) {
          toast('Vui lòng kiểm tra email để lấy mã xác thực để thực hiện việc thay đổi mật khẩu.')
          dispatch(setEmail(email))
          navigate(staticLinks.changePassword.url)
        } else {
          showMessage(error_message, (message) => {
            toast(message)
          })
        }
      })
      .catch((error) => {
        const { error_message } = error
        dispatch(setIsLoading(false))
        showMessage(error_message, (message) => {
          toast(message)
        })
      })
  }

  return (
    <div className="page page--center page--auth">
      <Helmet>
        <title>Quên mật khẩu - VÒNG XOAY QUYỀN NĂNG GALAXY AI</title>
      </Helmet>
      <div className="auth">
        <div className="container">
          <div className="box-light box-light--type2">
            <div className="box-light__main">
              <div className="box-light__body">
                <h2 className="title title--md text-center">Quên mật khẩu</h2>
                <div className="row">
                  <div className="col-md-6 col-lg-4 offset-lg-4 offset-md-3">
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                      <Input
                        label="Email"
                        name="email"
                        type="email"
                        id="email"
                        register={register}
                        error={errors.email}
                        setValue={setValue}
                      />
                      <div className="mt-3 text-center">
                        <Button
                          type="submit"
                          className="btn btn--primary px-5"
                          disabled={!isValid}
                          withAnimation
                        >
                          <span className="btn__text">Gửi</span>
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
